.fp-success-story-textBox {
    display: flex;
    /* position: absolute; */
    /* width: 47%; */
    /* height: calc(50% - 34px); */
    /* height: 65vh; */
    height: 100%;
    flex-direction: column;
    /* top: 40%;
    right: 3%;
    line-height: 12px; */
    line-height: 1;
}


.UPTC_CS_Square-textEditorClass {
    border-bottom: 8px solid #e2e2e2;
    border-top: 2px solid #e2e2e2;
    height: calc(100% - 34px) !important;
}

.text-editor-WIAC_diff_in_the_lives {
    background-color: transparent;
    flex-grow: 1;
    overflow-y: hidden;
}

.CD-CNP-textbox-toolbar, .rdw-option-wrapper {
    background-color: transparent;
    border: 1px solid transparent !important;
}

.rdw-editor-toolbar-custom {
    padding: 6px 5px 0;
    border-radius: 2px;
    border: 1px solid transparent;
    display: flex;
    justify-content: flex-start;
    background: white;
    flex-wrap: wrap;
    font-size: 15px;
    margin-bottom: 5px;
    -webkit-user-select: none;
    user-select: none;
}