.ap-login-field-container {
    padding: 10px;
    /* background: #FFFFFF 0% 0% no-repeat padding-box; */
    border: 2px solid #E0E0E0;
    border-radius: 19px;
}

.mb-15 {
    margin-bottom: 15px !important;
}

.login-button {
    background: #FFBC36 0% 0% no-repeat padding-box !important;
    border-radius: 18px !important;
    height: 67px;
    font: normal normal bold 20px/36px tablet-gothic;
    letter-spacing: 0.6px !important;
    color: #252525 !important;
    text-transform: uppercase !important;
}