.flashpoint {
    height: 100%;
}

.flashpoint>div:nth-child(1) {
    padding: 0% 2%;
}

@media only screen and (max-width: 450px) {
    .flashpoint>div:nth-child(1) {
        padding: 0% 2%;
    }
}

.fp-bottom-nav {
    height: 85px;
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    margin: 1rem 0rem;
    border: 1px solid;
    border-radius: 16px;
}

.fp-unit-description {
    font-size: 14px;
    color: #575757;
    margin: 12px 0px;
}

.fp-view-response-button {
    position: sticky !important;
    background-color: #000000 !important;
    padding: 3% !IMPORTANT;
    border-radius: 25px !important;
    bottom: 0%;
    width: 100%;
    right: 0%;
    justify-content: space-evenly !important;
    color: #FFFFFF !important;
    text-transform: none !important;
}

.fp-view-response-button_2 {
    background-color: darkgray !important;
    padding: 10px !important;
    border-radius: 25px !important;
    font-weight: bold !important;
    margin: 10px !important;
    position: absolute !important;
    bottom: 10px;
    /* width: 100%; */
    left: 0;
    text-transform: none !important;
}

.fp-quiz-back-button {
    background-color: darkgray !important;
    padding: 10px !important;
    border-radius: 25px !important;
    font-weight: bold !important;
    margin: 10px !important;
}

.fp-checklist-add-best-practice-button {
    color: white !important;
    background-color: green !important;
    padding: 10px !important;
    border-radius: 25px !important;
    font-weight: bold !important;
    margin: 10px !important;
}

.fp-unit-title {
    /* height: 15vh; */
    margin: 0px -2%;
    margin-bottom: 2%;
}

.fp-unit-title-unit-no {
    padding: 2% 4%;
    background-color: #1BAC00;
    color: white;
}

.fp-unit-completed {
    padding-top: 1rem;
    text-align: center;
}

/* .alternative-responses {
    height: 70%;
    overflow-y: auto;
} */

.alternative-responses .alternative-response:not(:last-child) {
    border-bottom: .1px solid #EEEEEE;
    padding-bottom: 3%;
    margin-bottom: 3%;
}

.alternative-response {
    position: relative;
    display: flex;
    margin: 2% 0%;
}

.response-body {
    margin-left: 5%;
}

.response-body .sender {
    font-weight: bold;
}

.fp-unit-quiz-container {
    height: 100%;
    background-color: #f3f3f3;
    padding: 5%;
}

.fp-unit-quiz-ans-container {
    width: 100%;
    margin: 5% 0% !important;
    background: white;
    padding: 5% !important;
    border-radius: 8px;
}

.fp-dashboard-tag {
    padding: 2%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #CCCCCC;
    border-radius: 11px;
    text-align: center;
    margin: 1%;
    cursor: pointer;
}

.fp-dashboard-tag-active {
    background-color: #ba1f00;
    color: white;
    box-shadow: rgb(0 0 0 / 16%) 0px 3px 6px;
}

.fp-dashboard-search-icon {
    justify-content: center;
    align-items: center;
    display: flex;
    width: 1.7rem;
    height: 1.7rem;
    border-radius: 50%;
    background-color: #FBD82A;
}

.fp-score {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 7rem;
    height: 7rem;
    border-radius: 50%;
    background-color: #FBD30D;
    border: 4px solid #FDE988;
    outline: 7px solid #FBD30D;
    margin: 5% auto;
    font-weight: bold;
    line-height: 1.2;
}

.fp-dashboard-score-card {
    position: relative;
    margin: 2%;
    padding: 3%;
    background-color: #FBD30D;
    border-radius: 36px;
    opacity: 0.88;
    text-align: center;
}

.fp-dashboard-score-unit-button {
    width: 100%;
    background-color: #000000 !important;
    padding: 5% !IMPORTANT;
    border-radius: 25px !important;
    font-weight: bold !important;
    justify-content: flex-end !important;
    color: #FFFFFF !important;
    text-decoration: none;
    text-transform: none !important;
}

.point-holder {
    /* display: grid;
    grid-template-columns: 1fr .1fr;
    padding: 1.2rem 2rem;
    border-bottom: 1px solid #EEEEEE;
    align-items: center;
     */
    display: grid;
    grid-template-columns: 1rem 1fr 0fr;
    padding: 1.2rem 2rem;
    /* margin-top: 1%; */
    border-bottom: 1px solid #EEEEEE;
    /* align-items: center; */
    font-weight: 500;
}

.point-holder .right-arrow-icon {
    width: 1.7rem;
    height: 1.7rem;
    border-radius: 50%;
    border: 1px solid black;
    background-color: black;
    color: white;
    margin: auto;
}

.fp-unit-back-left-arrow-icon {
    width: 1.7rem;
    height: 1.7rem;
    border-radius: 50%;
    border: 1px solid black;
    background-color: black;
    color: white;
}

.point-holder:hover {
    background-color: lightgray;
}

.position-relative {
    position: relative !important;
}

.point-description {
    margin-left: 15%;
}

.point-description ul {
    line-height: 26px;
    font-size: 20px;
}

.point-description li {
    margin: 1.5rem 0;
}

.fp-stepper-dots {
    width: .75rem;
    height: .75rem;
    background: white;
    margin: 0% 2%;
    border-radius: 50%;
}

.add-reponse-form {
    background-color: #FEE8AA;
    display: flex;
    flex-direction: column;
}

.point-description ul {
    margin-left: 15%;
}

.alternate-response-button {
    bottom: 15%;
    background-color: #FFF1C9 !important;
    color: black !important;
}

.fp-dashboard-results {
    text-align: center;
    font-weight: bold;
    color: darkgray;
    font-size: 1.5rem;
    font-style: italic;
}

.overflow-dot-2-lines {
    line-height: 1.2;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.fp-points-title {
    color: black;
    padding: 1.5rem 2rem;
    border-bottom: 1px solid #bdbdbd;
    text-align: center;
}

.fp-points-title-active {
    /* color: black; */
    padding: 1.5rem;
    border-bottom: 1px solid #bdbdbd;
    color: #a7a7a7;
    height: 16%;
}

.fp-points-description {
    background-color: rgb(255, 255, 255);
    border-radius: 27px;
    /* overflow-y: hidden; */
    /* margin-bottom: 2%; */
    height: 85%;
    min-height: 85%;
    /* overflow-y: auto; */
    position: relative;
}

.fp-card-view-fp-button {
    /* position: absolute !important; */
    background-color: #000000 !important;
    padding: 3% !IMPORTANT;
    border-radius: 25px !important;
    /* bottom: 4%; */
    /* width: 90%; */
    text-transform: none !important;
    /* right: 4.5%; */
    text-align: left;
    justify-content: space-evenly !important;
    color: #FFFFFF !important;
    line-height: 1.5 !important;
    letter-spacing: 0 !important;
    min-width: 100% !important;
}

.fp-alternative-response-button {
    /* display: block !important; */
    width: 100%;
    padding: 4% !important;
    border-radius: 24px !important;
    /* margin: 3% 0 !important; */
}

.fp-score-unit-vote {
    margin: 6%;
    padding: 10%;
    background-color: #FBD30D;
    border-radius: 26px;
    font-weight: bold;
}

.fp-icon {
    width: 2.5rem;
    margin-right: 3%;
}

.fp-unit-button {
    background-color: #000000 !important;
    padding: 3% !IMPORTANT;
    border-radius: 25px !important;
    margin: 2% 0% !important;
    text-transform: none !important;
    text-align: left;
    justify-content: space-evenly !important;
    color: #FFFFFF !important;
    line-height: 1.5 !important;
    letter-spacing: 0 !important;
    width: 100%;
}

.fp-unit-back-button {
    height: 10%;
    border-top: 1px solid rgb(189, 189, 189);
    padding: 6%;
    /* position: absolute; */
    /* bottom: 0px; */
    width: 100%;
}

.fp-alternate-solution-close-button {
    width: 2.25rem !important;
    height: 2.25rem !important;
    position: absolute;
    right: -10px;
    /* margin: 15px; */
    cursor: pointer;
    top: -45px;
    border-radius: 50%;
    background-color: #FBD30D;
}

.fp-unit-html-template {
    /* padding: 0.5% 17%; */
    /* margin: 6%; */
    padding: 6%;
}

.fp-unit-html-template li {
    margin: 7% 0%;
    /* padding: 0.5% 12%; */
}

.fp-unit-html-template ul {
    /* margin: 7% 0%; */
    /* padding: 0.5% 10%; */
    padding-left: 12%;
}

.fp-unit-floating-button {
    position: fixed !important;
    right: 4%;
    bottom: 1%;
}

.fp-fullscreen-img-close-button {
    position: absolute !important;
    /* margin-left: auto; */
    right: 1%;
}

.fp-view-all-fp-button {
    width: 96%;
    margin: 2% !important;
    background-color: #BA1F00 !important;
    padding: 7% !IMPORTANT;
    border-radius: 25px !important;
    justify-content: flex-end !important;
    color: #FFFFFF !important;
    text-decoration: none;
    text-transform: none !important;
}

.gray-background {
    background-color: #424242 !important;
}

.justify-content-center {
    justify-content: center !important;
}

.fp-alternate-avatar {
    width: 2.5rem!important;
    height: 2.5rem!important;
    font-size: 1rem !important;
    background-color: #c5c5c5 !important;
}

.fp-score-share-button {
    width: 100%;
    display: flex;
    align-items: center;
    border: 1px solid !important;
    border-radius: 24px;
    padding: 1rem !important;
}

.fp-score-share-button .share {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.walkthrough-button {
    width: 96%;
    margin: 2% !important;
    background-color: #BA1F00 !important;
    padding: 15px !IMPORTANT;
    border-radius: 25px !important;
    justify-content: flex-end !important;
    color: #FFFFFF !important;
    text-decoration: none;
    text-transform: none !important;
}

.fp-score-dashboard-share-container {
    position: absolute;
    bottom: 2%;
    width: 96%;
    /* width: 100%; */
}

.alternate-response-delete-icon {
    position: absolute;
    right: 0;
}

.alternate-response-load-more-button {
    width: 50%;
    background-color: white !important;
    margin-top: 3% !important;
    border: 1px solid darkgray !important;
    color: darkgray !important;
    text-transform: none !important;
}

.fp-point-display-none {
    display: none;
    /* padding-top: 15px; */
}

.alternate-response-number-loading {
    width: 1.1rem !important;
    height: 1.1rem !important;
    color: #fff !important;
    position: absolute !important;
    bottom: 8%;
}

.fp-card-title {
    font-weight: bold;
    text-align: center;
    font-size: 1.5rem !important;
}