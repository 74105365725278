.auth-form-cntr {
  margin: auto;
  max-width: 534px;
  padding: 0px 40px;
  /* box-shadow: 0 4px 8px 0 rgba(0,0,0,.2); */
}

.desktop {
  max-width: 1366px;
  margin: 0 auto;
}

.desktop,
.desktop .auth-cntr {
  height: 100%;
}

.desktop .login-artifact {
  background: #fff9e5 0% 0% no-repeat padding-box;
}

.desktop .rl-btns {
  display: flex;
}

.desktop .rl-btns .RegistrationBtn,
.desktop .rl-btns .loginBtn {
  margin-top: 0 !important;
}

.desktop .rl-btns .login-link-cntr {
  align-items: flex-start !important;
  margin-left: 25px !important;
  justify-content: center;
}

.mobile .login-artifact img {
  height: 313px;
}

.errorMsg {
  color: #e6111b;
  font-size: 0.875rem;
  padding: 5px 0;
}


.text-decoration-none {
  text-decoration: none !important;
}

.color-black {
  color: black;
}

.margin-auto {
  margin: auto !important;
}

.text-align-center {
  text-align: center !important;
}

.color-brown {
  color: rgb(186, 31, 0);
}

.fw-bold {
  font-weight: bold;
}

.forgot-password-bottom {
  display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 2% 0%;
}

.mt-15 {
  margin-top: 15px;
}