.session-event {
    font: normal normal normal 9px/18px tablet-gothic;
    letter-spacing: 0.18px;
    color: #323232;
    text-transform: uppercase;
    text-align: center;
}

.table-session tr th {
    padding: 5px;
}

.special-button:disabled, .special-button[disabled] {
    background-color: gray !important;
    color: white !important;
}

.session-event * {
    display: block;
    margin: auto;
}

.session-event img {
    width: 24px;
    height: 24px;
}

.sesion {
    font: normal normal bold 18px/18px tablet-gothic;
    color: #494949;
}

tr:first-child {
    background: #F2F2F2 0% 0% no-repeat padding-box;
}

.card {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #FFBC36;
    border-radius: 14px;
    padding: 15px 15px 13px;
    display: grid;
    grid-template-columns: 2fr 1fr;
    margin-top: 12px;
    text-decoration: none;
    color: inherit;
}

.card:hover {
    background: rgb(255, 188, 54) 0% 0% no-repeat padding-box;
    cursor: pointer;
}

.card:hover .card-link {
    color: #FFFFFF;
}

.card-title {
    font: normal normal bold 20px/24px tablet-gothic
}

.card-description {
    font: normal normal 300 18px/20px tablet-gothic;
    color: #494949;
    margin-top: 5px;
}

.card-link {
    margin-top: 5px;
    color: rgb(255, 188, 54);
    text-transform: uppercase;
    font-weight: bold;
}

.card-score-text {
    font: normal normal 600 12px/24px tablet-gothic;
    letter-spacing: 0.24px;
    text-transform: uppercase
}

.card-score {
    font: normal normal normal 42px/22px tablet-gothic;
    letter-spacing: -2.1px;
    color: #2BB01C
}

.card:hover .card-score {
    color: #FFFFFF
}

.card-total-score {
    font: normal normal 300 12px/24px tablet-gothic;
    letter-spacing: 0.24px;
    color: #767676
}

.card-left-right {
    margin: auto 30px;
}

.table-session {
    border-collapse: collapse;
    width: 100%;
}

.table-session tr {
    height: 90px;
    border-bottom: 1px solid #F2F2F2;
}

.table-session td a {
    padding: 25px;
}

.table-session th:first-child, .table-session td:first-child {
    text-align: left;
    padding: 10px 10px 10px 20px;
}

.table-sessionname {
    font: normal normal normal 16px/22px tablet-gothic;
    color: #252525;
}

.table-sessionname * {
    display: block;
}

.table-sessionname .session-progress {
    font: normal normal normal 10px/12px tablet-gothic;
    color: #2BB01C;
    text-transform: uppercase;
    margin-top: 5px;
}

.table-session-number {
    font: normal normal normal 10px/18px tablet-gothic;
    color: #6E6E6E;
    text-transform: uppercase;
    display: block;
}

.table-session td:not(:first-child) {
    text-align: center;
}

.table-content td svg {
    vertical-align: middle;
}

.otp-model .box {
    background: #FFFFFF 0% 0% no-repeat padding-;
    box-shadow: 0px 2px 5px #00000021;
    border-radius: 40px;
    margin-top: 40px;
    margin-bottom: 80px;
    padding: 100px 20px 10px 30px;
}

.otp-model .box .otp-text {
    font: normal normal bold 14px/18px tablet-gothic;
    letter-spacing: 0.56px;
    color: #4E4E4E;
    text-transform: uppercase;
    margin-bottom: 20px;
}

.otp-model .box .otp {
    text-align: center;
    width: 100%;
    margin-top: 20px;
}

.otp-model .box .otp input {
    font: normal normal normal 32px/32px tablet-gothic;
    letter-spacing: 0.32px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 2px solid #F3F3F3;
    border-radius: 20px;
    width: 70px !important;
    height: 90px;
}

.special-button {
    background: #FFBC00 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 3px 6px #00000029 !important;
    border-radius: 20px !important;
    text-align: center;
    /* width: 100%; */
    font: normal normal bold 14px/18px tablet-gothic !important;
    padding: 15px !important;
    /* margin-top: 15px !important; */
}

.points {
    color: #2BB01C;
    font: normal normal normal 12px/12px tablet-gothic;
}

.points :first-child {
    font: normal normal 300 28px/22px tablet-gothic;
    letter-spacing: -1.4px;
    display: block;
}

.custom-snackbar {
    box-shadow: 0px 3px 6px #00000029 !important;
    border-radius: 20px;
    padding-left: 20px;
}

.custom-snackbar .message {
    font: normal normal 600 16px/22px tablet-gothic;
    letter-spacing: 0.16px;
    margin: auto 0px auto 20px;
}

.custom-snackbar .icon {
    font: normal normal normal 12px/12px tablet-gothic;
    text-align: center;
    margin: auto;
}

.custom-snackbar .icon :first-child {
    font: normal normal 600 28px/22px tablet-gothic;
    display: block;
}

.bg-grey {
    background: #383838 0% 0% no-repeat padding-box !important;
}

.bg-white {
    background: white 0% 0% no-repeat padding-box !important;
}

.green {
    color: #2BB01C !important;
}

.white {
    color: white !important;
}

.bg-light-green {
    background: #D2FFCD 0% 0% no-repeat padding-box !important;
}

.assessment {
    margin: 30px 20px;
}

.assessment-heading {
    font: normal normal 600 22px/25px tablet-gothic;
}

.assessment .question-box {
    background: #F2F2F2 0% 0% no-repeat padding-box;
    padding: 25px 40px;
    margin: 10px 0px;
}

.assessment .answer {
    font: normal normal normal 16px/24px tablet-gothic;
    color: #676767;
    margin: 30px 30px;
}

.assessment .answer label {
    padding: 10px 0px;
}

.assessment .answer label:not(:last-child) {
    border-bottom: 1px solid #DBDBDB;
}

.assessment .question-box span:first-child {
    font: normal normal 600 18px/20px tablet-gothic;
    display: block;
    color: #BA1F00;
    margin-bottom: 10px;
}

.assessment .question-box span {
    font: normal normal 600 17px/25px tablet-gothic;
    color: #494949;
}

.w-100 {
    width: 100% !important;
}

.assessment .exit {
    text-align: center;
}

.assessment .exit a {
    text-decoration: none;
    color: #BA1F00;
    padding: 10px;
}

.assessment .exit a:hover {
    color: white;
    background-color: #BA1F00;
}

.assessment .exit a svg {
    vertical-align: middle;
    margin-right: 10px;
}

.assessment-result {
    margin: 50px;
    text-align: center;
}

.assessment-result .heading, .session-rating .heading {
    font: normal normal 600 22px/25px tablet-gothic;
}

.assessment-result .score {
    font: normal normal 600 16px/22px tablet-gothic;
    /* color: #FFFFFF; */
    /* background-color: forestgreen; */
    margin: 20px auto;
}

.assessment-result .score div:first-child {
    font: normal normal 600 60px/0px tablet-gothic;
    display: inline;
}

.circle {
    font-size: 20px;
    /* color:#fff; */
    text-align: center;
    line-height: 0;
    border-radius: 50%;
    border-style: solid;
}

.outer {
    width: 160px;
    height: 160px;
    margin-top: 5px;
    background-color: #FFFFFF;
    /* border-color: forestgreen; */
    border-style: solid;
}

.inner {
    padding: 25% 0;
    width: 130px;
    height: 130px;
    position: relative;
    top: -7px;
    /* background-color: forestgreen; */
}

.assessment-result .assessment-text {
    font: normal normal 800 18px/24px tablet-gothic;
    letter-spacing: 0.72px;
    /* color: forestgreen; */
    text-transform: uppercase;
    margin-bottom: 20px;
}

.assessment-result .points {
    font: normal normal normal 18px/22px tablet-gothic;
    color: #4D4D4D;
}

.session-rating {
    margin: 40px 30px;
}

.session-rating .rating-holder {
    border-top: 1px solid #DBDBDB;
    margin: 10px 0px;
    padding: 20px 0px;
    font: normal normal normal 16px/22px tablet-gothic;
}

.mt-15px {
    margin-top: 15px !important;
}

body {
    position: relative;
    min-height: 100vh;
    height: 100vh;
    max-width: 450px;
    margin: auto;
}

@media only screen and (min-width: 450px) {
    body {
        /* margin-top: 1%; */
        /* box-shadow: 0px 3px 6px #00000029; */
        /* padding: 10px; */
        /* border-radius: 25px; */
    }
}


#root {
    height: 100%;
}

.session-button span:first-child, .session-button {
    display: flex;
    flex-direction: column;
    text-decoration: none;
    align-items: center;
}

/* .session-button .text { */

.text {
    color: #6E6E6E;
    font: normal normal normal 10px/12px tablet-gothic;
    text-transform: uppercase;
    margin-top: 5px;
}

.table-session img {
    width: 33px;
    height: 33px;
}

.pt-10 {
    padding-top: 10px !important;
}

.text-center {
    text-align: center;
}

.mt-70 {
    margin-top: 70px;
}

.mt-30 {
    margin-top: 30px;
}

.border-red {
    border: 2px solid #BA1F00 !important;
}

.br-18 {
    border-radius: 18px !important;
}

.hover-bg-red:hover {
    background: #E4001B 0% 0% no-repeat padding-box !important;
}

.hover-color-white:hover {
    color: white !important;
}

.color-black {
    color: black !important;
}

.hover-color-white:hover {
    color: white !important;
}

.copyright {
    font: normal normal normal 14px/26px tablet-gothic;
    letter-spacing: 0.28px;
    color: #9B9B9B;
}

.text-center {
    text-align: center;
}

.error {
    color: red;
}

.ml-5 {
    margin-left: 5px;
}

.ml-20 {
    margin-left: 20px !important;
}

.mr-20 {
    margin-right: 20px !important;
}

.text-right {
    text-align: right;
}

.tablet-gothic {
    font-family: tablet-gothic, sans-serif;
}

.w-50 {
    width: 50% !important;
}

.flex {
    display: flex;
}

.align-item-end {
    align-items: end;
}

.grey {
    color: grey;
}

.h-fit {
    height: fit-content;
}

.v-center {
    margin-top: auto;
    margin-bottom: auto;
}

.w-25 {
    width: 25% !important;
}

.ml-10 {
    margin-left: 10% !important;
}

.mr-10 {
    margin-right: 10% !important;
}

.mt-auto {
    margin-top: auto !important;
}

.mb-auto {
    margin-bottom: auto !important;
}

.fs-14 {
    font-size: 14px !important;
}

.fs-16 {
    font-size: 16px !important;
}

.ls-18 {
    line-height: 18px !important;
}

.ls-22 {
    line-height: 22px !important;
}

.fw-300 {
    font-weight: 300 !important;
}

.triangle {
    border-bottom: 15px solid #000;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    width: 0;
    height: 0;
}

.w-fit {
    width: fit-content;
}

.margin-auto {
    margin: auto;
}

.policy {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    text-align: center;
}

.policy a {
    padding-top: 10px;
    padding-bottom: 10px;
    text-decoration: none;
}

.ml-auto {
    margin-left: auto !important
}

.mr-auto {
    margin-right: auto;
}

.mt-40px {
    margin-top: 40px;
}

.mb-40px {
    margin-bottom: 40px;
}

.red {
    color: red;
}

.greenyellow {
    color: #bde100;
}

.lawngreen {
    color: #67c507;
}

.forestgreen {
    color: #1bac00;
}

.sandybrown {
    color: #ffc043 !important;
}

.text-upper {
    text-transform: uppercase;
}

.border {
    border: 1px solid black !important;
}

.br-25px {
    border-radius: 25px !important;
}

.border-sandybrow {
    border-color: sandybrown !important;
}

.fw-bold {
    font-weight: bold !important;
}

.pl-30px {
    padding-left: 30px !important;
}

.pr-30px {
    padding-right: 30px !important;
}

.pt-10px {
    padding-top: 10px !important;
}

.pb-10px {
    padding-bottom: 10px !important;
}

/* padding: 10px 30px; */

/* margin-top: 15px; */

.indian-red {
    color: #c96551
}

.ml-10px {
    margin-left: 10px;
}

.f-right {
    float: right;
}

.pointer {
    cursor: pointer;
}

.lime-green {
    color: limegreen !important;
}

.pointer:hover {
    opacity: 0.5;
}

.flex-center {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

.blinking {
    color: #BA1F00;
    /* margin-top: -15px; */
    margin-bottom: 10px;    
    animation: blinkingText 1.5s infinite;
    text-transform: uppercase;
    font-size: 1rem;
}

@keyframes blinkingText {
    from {
        opacity: 1;
        color : red;
        }
    to {
        opacity: .1;
        color : red;
        }
    /* 0% {
        opacity: 0;
    } */
    /* 50% {
        opacity: .5;
    }
    100% {
        opacity: 1;
    } */
}