.text-editor-wrapper {
    background-color: white;
    border-radius: 4px;
    padding: 8px;
    overflow-y: scroll;
    height: 150px;
    margin-bottom: 15px;
    flex-grow: 1;
}

.public-DraftEditor-content {
    overflow-wrap: anywhere !important;
}

.DraftEditor-root {
    height: unset !important;
}

.public-DraftStyleDefault-block {
    margin: 0.5em !important;
}