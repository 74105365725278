*{ margin: 0px; padding: 0px; box-sizing: border-box; }
.e-link:hover {
 background: transparent;
}

.external-links { line-height: 1.65; color: #505050; font-size: 15px;}
.external-links p{ margin: 0px 0px 12px; }
.external-links li{margin-left: 16px;}
.highlighted-patch{margin-top: 16px}

.f15_b{font-weight: 700; color: #000}