/* .slider-tooltip {	
    position: absolute;			
    text-align: center;			
    width: 100px;					
    height: 100px;					
    padding: 2px;
    overflow-y: scroll;					
    background: lightsteelblue;	
    border: 0px;		
    border-radius: 8px;				
} */

.myapp-slider-1 {
    position: absolute;
    width: 32%;
    top: 48%;
    right: 57%;
    transform: rotate(30deg);
}

.myapp-slider-2 {
    position: absolute;
    top: 34%;
    right: 47.5%;
    height: 20%;
    /* transform: rotate(90deg); */
}

.myapp-slider-3 {
    position: absolute;
    width: 32%;
    top: 48%;
    right: 14%;
    transform: rotate(330deg);
}

.myapp-slider-4 {
    position: absolute;
    height: 18%;
    top: 57.5%;
    right: 25%;
    transform: rotate(300deg);
}

.myapp-slider-5 {
    position: absolute;
    /* width: 20%; */
    height: 20%;
    top: 66%;
    right: 47.5%;
    /* transform: rotate(270deg); */
}

.myapp-slider-6 {
    position: absolute;
    width: 32%;
    top: 65%;
    right: 58%;
    transform: rotate(330deg);
}

@media only screen and (max-width: 600px) {
    .MuiDialog-container .MuiDialog-paperFullScreen {
        display: flex;
    }

    .myapp-slider-5 {
        right: 45.6%;
    }

    .myapp-slider-1 {
        top: 46.5%;
        right: 57%;
    }

    .myapp-slider-3 {
        top: 46%;
        right: 14%;
    }

    .myapp-slider-6 {
        top: 63%;
        right: 60%;
    }

    .myapp-slider-4 {
        right: 22%;
    }

    .myapp-slider-2 {
        right: 45.6%;
    }
}

.takeaway-swipe .react-swipeable-view-container div {
    display: flex;
}

.takeaway-swipe .react-swipeable-view-container div *:first-child {
    margin: auto;
}

.takeaway-swipe .MuiMobileStepper-dots {
    margin: auto;
}