/* html, body, #root{height:100%} */

.overFlowYAuto {
    overflow-y: auto;
}

.textbox-scrollable-darkgray::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

.textbox-scrollable-darkgray::-webkit-scrollbar-thumb {
    background-color: #989696;
    border-radius: 6px;
}

.textbox-scrollable-darkgray::-webkit-scrollbar-track {
    box-shadow: none;
    margin: 6px;
}

* {
    font-family: tablet-gothic !important;
    font-size: 1rem !important;
}

.redirect-page {
    margin: 10%
}

.redirect-page * {
    margin-bottom: 5%
}

.redirect-page *:not(:first-child) {
    margin-top: 5%
}