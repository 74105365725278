@import url("https://fonts.googleapis.com/css2?family=Merriweather+Sans:wght@300;400;700;800&family=Merriweather:wght@400;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Merriweather:wght@300;400;700&display=swap");
* {
    font-family: "Merriweather Sans", sans-serif;
    font-size: 14px;
}

.flashpoint * {
    font-family: ff-tisa-sans-web-pro !important;
    font-size: 16px;
}

@media only screen and (min-width: 320px) {
    /* body {
         padding: 0% 2%; 
    } */
    .flashpoint *, * {
        font-size: 1.08rem;
    }
    .flashpoint .small-text {
        font-size: 0.75rem;
    }
    .flashpoint .small-text_2 {
        font-size: 0.9rem;
    }
    .flashpoint .large-text {
        font-size: 1.18rem;
    }
}

@media only screen and (min-width: 450px) {
    /* body {
         padding: 0% 2%; 
    } */
    .flashpoint *, * {
        font-size: 1.08rem;
    }
    .flashpoint .small-text {
        font-size: 0.75rem;
    }
    .flashpoint .small-text_2 {
        font-size: 0.9rem;
    }
    .flashpoint .large-text {
        font-size: 1.18rem;
    }
}

@media only screen and (min-width: 768px) {
    /* body {
         padding: 0% 2%; 
    } */
    .flashpoint *, * {
        font-size: 1.15rem;
    }
    .flashpoint .small-text {
        font-size: 0.85rem;
    }
    .flashpoint .small-text_2 {
        font-size: 1rem;
    }
    .flashpoint .large-text {
        font-size: 1.28rem;
    }
}

@media only screen and (min-width: 1024px) {
    /* body {
         padding: 0% 2%; 
    } */
    .flashpoint *, * {
        font-size: 1.3rem;
    }
    .flashpoint .small-text {
        font-size: 0.9rem;
    }
    .flashpoint .small-text_2 {
        font-size: 1.15rem;
    }
    .flashpoint .large-text {
        font-size: 1.48rem;
    }
}

.-hf {
    font-family: "Merriweather", serif;
}

.font-tablet-gothic {
    font-family: tablet-gothic !important;
}

.font-weight-bold {
    font-weight: bold;
}

.font-size-1_5-rem {
    font-size: 1.5rem;
}

.font-tisa-sans {
    font-family: ff-tisa-sans-web-pro, sans-serif;
}

.font-style-italic {
    font-style: italic;
}