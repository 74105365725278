.d-flex {
    display: flex;
}

.ml-auto {
    margin-left: auto;
}

.justify-content-space-evenly {
    justify-content: space-evenly;
}

.align-items-center {
    align-items: center;
}

.mx-auto {
    margin-left: auto;
    margin-right: auto;
}

.d-block {
    display: block;
}

.h-100 {
    height: 100%;
}

.h-auto {
    height: auto;
}

.flex-wrap {
    flex-wrap: wrap;
}

.text-decoration-none {
    text-decoration: none !important;
}

.overFlowYVisible {
    overflow-y: visible !important;
}